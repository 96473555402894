import React, { Component } from "react"

export class GoogleMap extends Component {
  render() {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2321.2181272855637!2d18.47010841611387!3d54.42378220116084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd9f87c1f4f041%3A0x9ed4dd60deb9cefb!2sKozioro%C5%BCca%2021%2C%2080-299%20Gda%C5%84sk!5e0!3m2!1sen!2spl!4v1580314310378!5m2!1sen!2spl"
        allowFullScreen
        title="Office address"
      />
    )
  }
}
