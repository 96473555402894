import React, { useState } from "react"
import { GoogleMap } from "../google-maps/google-map"

import styles from "./contact.module.scss"

const FormSubmissionResult = Object.freeze({
  SUCCESS: Symbol("success"),
  ERROR: Symbol("error"),
  NONE: Symbol("none"),
})

function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [status, setStatus] = useState(FormSubmissionResult.NONE)

  const handleSubmit = (e) => {
    const data = { "form-name": "contact", name, email, message }

    const encode = (data) => {
      const formData = new FormData()

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })

      return formData
    }

    setStatus(FormSubmissionResult.NONE)

    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": "Contact",
        ...data,
      }),
    })
      .then(() => {
        setStatus(FormSubmissionResult.SUCCESS)
        setEmail("")
        setMessage("")
        setName("")
      })
      .catch((error) => {
        setStatus(FormSubmissionResult.ERROR)

        console.error(error)
      })

    e.preventDefault()
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "name") {
      return setName(value)
    }

    if (name === "email") {
      return setEmail(value)
    }

    if (name === "message") {
      return setMessage(value)
    }
  }

  return (
    <main>
      <article>
        <div>
          <div className="row">
            <GoogleMap />
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h2 className={styles.sectionTitle}>Contact Form</h2>
              <p>
                <form
                  name="Contact"
                  method="post"
                  onSubmit={handleSubmit}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <span className={styles.hidden}>
                    <label>
                      Don’t fill this out if you're human:{" "}
                      <input name="bot-field" />
                    </label>
                  </span>
                  <span className={styles.controlWrap}>
                    {status === FormSubmissionResult.SUCCESS && (
                      <p className={`${styles.message} ${styles.success}`}>
                        Dziękuję za wiadomość, odpowiem w możliwie szybkim
                        terminie!
                      </p>
                    )}
                    {status === FormSubmissionResult.ERROR && (
                      <p className={`${styles.message} ${styles.error}`}>
                        Przepraszam ale wystąpił błąd w trakcie wysyłania
                        wiadomości :(
                      </p>
                    )}
                  </span>
                  <span className={styles.controlWrap}>
                    <input
                      type="text"
                      name="name"
                      size="40"
                      className={styles.textInput}
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Name"
                      required
                      onChange={handleChange}
                    />
                  </span>
                  <span className={styles.controlWrap}>
                    <input
                      type="email"
                      name="email"
                      size="40"
                      className={styles.textInput}
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                    />
                  </span>
                  <span className={styles.controlWrap}>
                    <textarea
                      name="message"
                      cols="40"
                      rows="10"
                      className={styles.textArea}
                      aria-invalid="false"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                    />
                  </span>
                  <input
                    type="submit"
                    value="Send a Message"
                    className={styles.submitButton}
                  />
                </form>
              </p>
            </div>
            <div className="col-sm-6">
              <h2 className={styles.sectionTitle}>Contact Info</h2>
              <ul className={styles.infoList}>
                <li>
                  Address: <span>Ul. Koziorożca 21/5, 80-299 Gdańsk</span>
                </li>
                <li>
                  Website: <span>www.dragonia.org.pl</span>
                </li>
                <li>
                  Email: <span>lukasz@dragonia.org.pl</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </article>
    </main>
  )
}

export default Contact
